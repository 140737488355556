import { unsealData } from "iron-session";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Image from 'lib/image'
import { Player } from "@lottiefiles/react-lottie-player";
import { useContext, useState } from "react";
import SyncLoader from "react-spinners/SyncLoader";
import { Context } from "components/context";
import conv from 'binstring';
import { checkUser } from "pages/api/auth/sendotp";
import { useRouter } from "next/router";
import useUser from "lib/useUser";
import { FcApproval } from "react-icons/fc";
import LabelBottomNavigation from "components/navs/bottomnav";
import { UserAgent } from '@quentin-sommer/react-useragent'
import Link from "next/link";

export default async function whatsapp(ctx) {
    const { data } = ctx.query
    const base64 = conv(data, { in: 'hex', out: 'utf8' });
    const unseal = await unsealData(base64, {
        password: process.env.SECRET_COOKIE_PASSWORD,
    });
    if (unseal.waId) {
        const mobile = "+" + unseal.waId
        const { user, exists } = await checkUser(mobile)

        if (exists.length === 0) {
            ctx.req.session.user = { id: user.id }
            const ok = await ctx.req.session.save()
            if (unseal.s === 'web') {
                return { waId: unseal.waId, isLoggedIn: true, s: 'web' }
            }
            else {
                ctx.res.writeHead(302, {
                    Location: '/profile'
                })
                ctx.res.end();
            }
            return {}
        } else {
            return { waId: unseal.waId, exists, s: unseal.s? unseal.s : null } 
        }
    }
    else {
        return { error: 'WhatsApp Login link expired or invalid! Please try again' }
    }
}

export function  WhatsAppExists({ data, whatsappData }) {
    const { mutateUser } = useUser()
    const context = useContext(Context);
    const [loading, setLoading] = useState(false)
    const [isLoggedIn, setIsLoggedIn] = useState(false)
    const [state, setState] = useState({ name: '', email: '', avatar: 1 });
    const router = useRouter()

    const handleCallback = () => {
        if (whatsappData.exists) {
            if (whatsappData.exists.includes(1) && state.name.length < 3) {
                context.dispatch({ type: 'error', message: 'Name must be atleast 3 characters long' })
                return;
            }
            // if (whatsappData.exists.includes(2) && state.email.match(/^\S+@\S+$/) === null) {
            //     context.dispatch({ type: 'error', message: 'Please enter a valid e-mail' })
            //     return;
            // }
            if (whatsappData.exists.includes(3) && !state.avatar) {
                context.dispatch({ type: 'error', message: 'Please choose your Avatar' })
                return;
            }
        }
        setLoading(true)

        fetch('/api/auth/whatsapp', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ state, data })
        })
            .then(res => res.json())
            .then(data => {
                setLoading(false)
                if (data.isLoggedIn) {
                    setIsLoggedIn(true)
                    mutateUser()
                    if (whatsappData.s !== 'web') {
                        context.dispatch({ type: 'success', message: 'Logged in successfully!' })
                        router.push('/profile');
                    }
                }
                else if (data.error) {
                    context.dispatch({ type: 'error', message: data.error })
                }
                else {
                    context.dispatch({ type: 'error', message: 'Something went wrong! Please try again' })
                }
            })
            .catch(err => {
                setLoading(false)
                context.dispatch({ type: 'error', message: 'Something went wrong! Please try again' })
            })
    }

    return whatsappData.s === 'web' && isLoggedIn ? <CloseWindow /> : <div className="mb-8">
        <div className="body-p-x md:p-4">
            <Player
                autoplay
                loop
                src="https://assets4.lottiefiles.com/packages/lf20_jcikwtux.json"
                style={{ height: '150px', width: '150px' }}
            >
            </Player>

            <p className="text-lg text-center text-gray-700">Signing in with +{whatsappData.waId}
            </p>
            <div className="flex justify-center text-xs mt-2 mb-8">
                <span className="font-semibold cursor-pointer">Change Number</span>
            </div>
        </div>
        <div className="body-p-x md:p-4">
            {whatsappData.exists.includes(1) ? <TextField
                id="name"
                value={state.name}
                type="text"
                variant="outlined"
                label="Your Name"
                className="w-full mt-6"
                onChange={(e) => setState({ ...state, name: e.target.value })} /> : null}
        </div>
        {whatsappData.exists.includes(3) ? <>
            <p className='font-medium mt-6 mb-4 text-center'>Choose Avatar</p>

            <div className="flex justify-center space-x-8">
                <div className={`cursor-pointer rounded-xl border-2 flex items-center justify-center ${!state.avatar || state?.avatar == 1 ? 'border-hac-800 shadow-xl' : 'border-white'}`} onClick={() => setState({ ...state, avatar: 1 })}>
                    <Image src={'assets/icons/male.jpg'} width={90} height={90} className="rounded-xl" />
                </div>
                <div className={`cursor-pointer rounded-xl border-2 flex items-center justify-center ${state?.avatar == 2 ? 'border-hac-800 shadow-xl' : 'border-white'}`} onClick={() => setState({ ...state, avatar: 2 })}>
                    <Image src={'assets/icons/female.jpg'} width={90} height={90} className="rounded-xl" />
                </div>
            </div>
        </> : null}
        <div className="body-p-x py-2">
            <div className="relative mt-10">
                <Button
                    size="large"
                    onClick={handleCallback}
                    fullWidth
                    variant="contained"
                    color="primary"
                    disabled={loading}
                >
                    LOGIN
                </Button>
                <div className="buttonProgress">
                    <SyncLoader
                        color="#00BF1B"
                        size={10}
                        loading={loading}
                    />
                </div>
            </div>
        </div>
    </div>
}

export function CloseWindow() {
    const { user } = useUser()
    return <div className="h-screen w-screen container flex flex-col items-center justify-center">
        <FcApproval className="text-4xl" />
        <p className="pt-2 text-center">Hi {user?.name}, you've been signed in successfully!</p>
        <p className="pt-2 text-center">You can now safely <strong>close this tab</strong> and go back to the previous tab.</p>
        <div className="flex justify-center space-x-4 mt-6">
            <Link href="/" legacyBehavior>
                <Button size="small" color="primary" variant="contained">Go Home</Button>
            </Link>
            <Link href="/profile" legacyBehavior>
                <Button size="small" color="secondary" variant="contained">My Profile</Button>
            </Link>
        </div>
        <UserAgent tablet mobile>
            <LabelBottomNavigation />
        </UserAgent>
    </div>
}