import { customAlphabet } from 'nanoid';
import prisma from '../../../prisma/db';

export default async function handle(req, res) {
    try {
        let { mobile } = await req.body
        mobile = mobile.replace(/[- .]/g, "")

        if (mobile === '+911234567890') {
            await prisma.otp.create({
                data: {
                    mobile,
                    otp: '123456'
                }
            })
        }

        const otp = (mobile === '+911234567890' ? true : await sendotp(mobile))

        if (otp !== true || otp.error) {
            res.json({ error: otp.error || 'Something went wrong! Please try again!' })
            return;
        }
        const { exists } = await checkUser(mobile)
        res.json({ exists, otp })

    } catch (error) {
        console.log(error)
        const { response: fetchResponse } = error
        res.status(fetchResponse?.status || 500).json({ error: error })
    }
}

async function sendotp(mobile) {
    const nanoid = customAlphabet('1234567890', 6)
    const otp = nanoid();

    //check if more than 3 otp in last half hour
    let otps = await prisma.otp.findMany({
        where: {
            mobile,
            created_at: {
                gte: new Date(new Date().getTime() - 30 * 60000)
            }
        }
    })

    let count = otps.length;

    if (count >= 3)
        return { error: `Too many requests. Please try again in ${30 - Math.ceil((new Date().getTime() - otps[0].created_at.getTime()) / 60000)} minutes` }

    const response = await fetch(`https://api.exotel.com/v1/Accounts/hireacamp1/Sms/send.json`, {
        method: 'POST',
        body: new URLSearchParams({
            From: "HACAMP",
            To: mobile,
            Body: `OTP for login to HireACamp: ${otp}
            
Valid for 30 minutes. Please do not share this with anyone.`,
            DltEntityId: "1201171714016863460",
            DltTemplateId: "1207172777516330305",
            SmsType: "transactional",
            Priority: "high"
        }),
        headers: {
            'accept': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': `Basic ${Buffer.from(`${process.env.EXOTEL_KEY}:${process.env.EXOTEL_TOKEN}`).toString('base64')}`
        }
    });

    const json = await response.json()
    const status = json?.SMSMessage?.Status;
    console.log(json)
    if (!status || status === 'failed-dnd' || status === 'failed') {
        return { error: `Failed to send OTP. Please try again!` }
    }

    await prisma.otp.create({
        data: {
            mobile,
            otp
        }
    })

    return true;
}

export async function checkUser(mobile) {
    let user = await prisma.user.findUnique({
        where: { mobile },
        select: {
            id: true,
            name: true,
            email: true,
            meta: {
                select: {
                    gender: true
                }
            }
        }
    })

    let exists = [];

    if (!user)
        exists = [1, 2, 3];
    else {
        if (!user.name || user.name === '')
            exists.push(1)
        if (!user.email || user.email === '')
            exists.push(2)
        if (user?.meta?.gender !== 1 && user?.meta?.gender !== 2)
            exists.push(3);
    }

    return { user, exists }
}