import React from 'react';
import dynamic from 'next/dynamic';
import Image from 'lib/image'
import { UserAgentProvider, UserAgent } from '@quentin-sommer/react-useragent'
import Header from "../components/navs/header";
import Meta from "../components/meta";
import whatsapp, { CloseWindow, WhatsAppExists } from 'components/login/whatsapp';
import { withSessionSsr } from 'lib/session';

const LoginPage = dynamic(() => import('components/login/login'), { ssr: false });

function Login(props) {

    return <UserAgentProvider ua={props.ua}>
        <div className="flex flex-col h-screen">
            <UserAgent tablet computer>
                <Header />
            </UserAgent>
            <div className="grid md:grid-cols-2 grow md:items-center">
                <UserAgent tablet computer>
                    <div className="relative h-full">
                        <Image src={'assets/banners/login.jpg'} layout="fill" objectFit="cover" />
                    </div>
                </UserAgent>
                <div className="md:px-10">
                    {props.whatsappData?.isLoggedIn?<CloseWindow {...props} />:(props?.whatsappData?.exists?.length?<WhatsAppExists {...props} />:
                    <LoginPage {...props} />)}
                </div>
            </div>
        </div>
        <Meta title="Login to Hire A Camp | India's Best Camping Booking Site"
            description="Login to your Hire A Camp account to access special discounts and offers on camping anywhere in India."
            image="/assets/backgrounds/login.png" />
    </UserAgentProvider>
}

export const getServerSideProps = withSessionSsr(async function (ctx) {
    console.log(ctx.query)
    let whatsappData = null;
    let app_login = null;
    if(ctx.query.data) {
        whatsappData = await whatsapp(ctx)
    }
    if(ctx.query.app_login) {
        app_login = ctx.query.app_login + '&state=' + ctx.query.state
    }
    return {
        props: {
            ua: ctx.req ? ctx.req.headers['user-agent'] : navigator.userAgent, red: ctx.query.red?ctx.query.red:null, data: ctx.query.data?ctx.query.data:null, whatsappData, app_login
        }
    }
})

export default Login;
