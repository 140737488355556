import React from 'react';
import Head from 'next/head';

function Meta(props) {
    //We made camping really simple. Head to the mountains️, Sleep under the stars.. Walk by the lake.. Go for a morning waterfall trek.. Enjoy bonfire with friends.. Listen to the buzzing flies on the wildflowers... With Hire a Camp, Get high on nature!
    var title = "Hire A Camp | Camping, Glamping, Treks, Cottages, and more";
    var description = "Find and book your next outdoor adventure with ease at HireACamp - the ultimate marketplace for camping, glamping, treks, cottages, and resorts in India.";
    var image = process.env.NEXT_PUBLIC_CDN+'thumbnail.jpeg';

    if(props.title)
        title = props.title;
    if(props.description)
        description = props.description;
    if(props.image)
        image = props.image;

    return <Head>
        <title key="title">{title}</title>

        <meta
            key="description"
            name="description"
            content={description}
        />
        <meta
            key="og:type"
            property="og:type"
            content="website"
        />
        <meta
            key="og:title"
            property="og:title"
            content={props.ogtitle?props.ogtitle:title}
        />
        <meta
            key="og:description"
            property="og:description"
            content={props.ogdesc?props.ogdesc:description}
        />
        <meta key='fb:app_id' property='fb:app_id' content='1820331688030845'/>
        <meta
            key="og:image"
            property="og:image"
            content={image}
        />
        {props.width && <meta property="og:image:width" content={props.width} />}
        {props.height && <meta property="og:image:height" content={props.height} />}
        <meta name='twitter:card' content='summary_large_image' />
        <meta name='twitter:site' content='@hire_a_camp' />";
    </Head>
}

export default Meta;
